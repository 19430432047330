import React, { useState } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import {Container, TextField, Button, Typography, Box} from '@mui/material';
import {useApiClient} from "../../base/hooks/useApiClient";

export function RegisterPage() {
    const [formError, setFormError] = useState();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const apiClient = useApiClient();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const response = await apiClient.post('register', { username, email, password });

        if (!response.ok) {
            const { error } = await response.json();
            setFormError(error);

            return;
        }

        navigate('/login');
    };

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Register
                </Typography>
                <form onSubmit={handleSubmit}>
                    {formError && (
                        <Typography color="error" variant="body1" gutterBottom>
                            {formError}
                        </Typography>
                    )}
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Register
                    </Button>
                </form>
                <Typography variant="body2" mt={2}>
                    Have an account? <Link to="/login">Login here</Link>
                </Typography>
            </Box>
        </Container>
    );
}
