import {Button, IconStart} from "../index";
import {TriangleIcon} from "./TriangleIcon";
import {SquareIcon} from "./SquareIcon";
import React, {useState} from "react";
import styled from "styled-components";

interface Props {
    playText: string;
    pauseText: string;
    onStart: () => void;
    onStop: () => void;
}

const StartButton = styled(Button)`
    background-color: #101010;
    color: #ececf1;
    
    &:hover {
        background-color: #404040;
    }
`;

const StopButton = styled(Button)`
    background-color: #fff;
    color: #101010;
    border: 1px solid #101010;
`;

export function PlayPauseButton({ playText, pauseText, onStart, onStop }: Props) {
    const [isPlaying, setIsPlaying] = useState(false);

    function handleStart() {
        setIsPlaying(true);
        onStart();
    }

    function handleStop() {
        setIsPlaying(false);
        onStop();
    }

    if (isPlaying) {
        return (
            <StopButton onClick={handleStop}>
                <IconStart><SquareIcon /></IconStart>
                <span>{pauseText}</span>
            </StopButton>
        );
    }

    return (
        <StartButton onClick={handleStart}>
            <IconStart><TriangleIcon /></IconStart>
            <span>{playText}</span>
        </StartButton>
    );
}
