import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {AuthProvider} from "./context";
import {LoginPage} from "./components/pages/login";
import {RegisterPage} from "./components/pages/register";
import {SessionPage} from "./components/pages/session";
import {GlobalStyle} from "./GlobalStyle";
import {SessionListenPage} from "./components/pages/session-listen";
import {CreateSessionPage} from "./components/pages/session-create";
import {ProtectedRoute} from "./components/base/ProtectedRoute";
import { NonProtectedRoute } from './components/base/NonProtectedRoute';

export function App() {
  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={<NonProtectedRoute />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Navigate to="/sessions" />} />
              <Route path="/sessions" element={<CreateSessionPage />} />
              <Route path="/sessions/:sessionId" element={<SessionPage />} />
            </Route>

            {/* both authenticated and non-authenticated users should have access to this page */}
            <Route path="/sessions/:sessionId/listen" element={<SessionListenPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </>
  );
}
