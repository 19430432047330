import {useEffect, useRef, useState} from "react";

export function useWebsocket() {
    // use refs to fix issue with binding
    const websocketRef = useRef<WebSocket | undefined>();

    function setWebsocket(websocket: WebSocket) {
        websocketRef.current = websocket;
    }

    const start = (onMessage: WebSocket['onmessage']) => {
        return new Promise<void>((resolve, reject) => {
            const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}${window.location.pathname}`);
            socket.binaryType = 'arraybuffer';
            socket.onmessage = onMessage;

            socket.onopen = () => {
                close();
                setWebsocket(socket);
                resolve();
            };

            socket.onerror = (error) => {
                reject(error);
            };
        });
    };

    const send: WebSocket['send'] = (data) => {
        if (!websocketRef.current) {
            throw new Error('Websocket not started');
        }

        return websocketRef.current.send(data);
    };

    const close = () => {
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.close();
        }
    };

    useEffect(() => {
        return close();
    });

    return { start, send: send, close };
}
