import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html, body {
        padding: 0;
        margin: 0;
        position: relative;
        width: 100%;
        height: 100%;
        font-family: 'Assistant', sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        color: #18181b;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #root {
        position: relative;
        width: 100%;
        height: 100%;
    }
`;
