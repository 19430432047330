import styled from "styled-components";

export const Button = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    border: none;
    background-color: #ececf1;
    color: #101010;
    border-radius: 1000px;
    padding: 8px 24px;
    min-height: 32px;
    transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
    outline: none;
    cursor: pointer;
    
    &:hover {
        background-color: #d8d8d8;
    }
`;

const Icon = styled.span`
    display: flex;
`;

export const IconStart = styled(Icon)`
    margin-left: -8px;
    
    svg {
        width: 16px;
        height: 16px;
    }
`;
