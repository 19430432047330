import React, {useRef} from "react";
import {Toggle} from "../Toggle";

const defaultSoundEnabled = false;

export function useSoundEnabled() {
    const soundEnabledRef = useRef(defaultSoundEnabled);

    const setSoundEnabled = (enabled: boolean) => {
        soundEnabledRef.current = enabled;
    };

    const SoundToggle = () => (
        <Toggle onText="sound" offText="off" defaultEnabled={defaultSoundEnabled} onChange={setSoundEnabled} />
    );

    return { soundEnabledRef, SoundToggle };
}
