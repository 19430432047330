import React from 'react';

export function SquareIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round">
            <polygon points="6 6 18 6 18 18 6 18"></polygon>
        </svg>
    );
}