import {useAuth} from "../../context";
import {Navigate, Outlet} from "react-router-dom";

export function NonProtectedRoute() {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
}
