export class ApiClient {
    private baseUrl: string = process.env.REACT_APP_API_URL ?? '';

    getBaseUrl(): string {
        return this.baseUrl;
    }

    async get(url: string): Promise<Response> {
        return this.request(url);
    }

    async post(url: string, body: any): Promise<Response> {
        return this.request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    }

    private async request(url: string, options?: RequestInit): Promise<Response> {
        return fetch(`${this.baseUrl}/${url}`, {
            ...options,
            headers: {
                ...options?.headers,
                ...this.getAuthorizationHeader(),
            },
        });
    }

    private getAuthorizationHeader(): Record<string, string> {
        const token = localStorage.getItem('authToken');

        return token ? { Authorization: token } : {};
    }
}
