import React, {useMemo, useState} from 'react';
import {Page} from "../../layout";
import {Container, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import { useAsync } from 'react-use';
import { useParams } from 'react-router-dom';
import {useWebsocket} from "../../base/hooks";
import {useSoundEnabled} from "../../base/hooks";
import {PlayPauseButton} from "../../base/PlayPauseButton";
import {useApiClient} from "../../base/hooks/useApiClient";

export function SessionListenPage() {
    const { soundEnabledRef, SoundToggle } = useSoundEnabled();
    const [language, setLanguage] = useState<string>('');
    const { sessionId } = useParams<{ sessionId: string }>();
    const apiClient = useApiClient();

    const { value: session, loading } = useAsync(async () => {
        const response = await apiClient.get(`sessions/${sessionId}`);

        if (!response.ok) {
            console.error('Error fetching session data');
        }

        const { session } = await response.json();

        const language = session.translation_languages.split(',')[0];
        setLanguage(language);

        return session;
    });

    const websocket = useWebsocket();

    const handleJoin = async () => {
        await websocket.start((event) => {
            if (!soundEnabledRef.current) {
                console.log('Sound playback not enabled by user');
                return;
            }

            const data = JSON.parse(event.data);

            if (data.translationLanguage !== language) {
                return;
            }

            if (data.localPath) {
                const audio = new Audio(`${apiClient.getBaseUrl()}/${data.localPath}`);
                audio.play();
            }
        });
    };

    const onStopPlaying = () => {
        websocket.close()
    };

    const availableLanguages: string[] = useMemo(
        () => session?.translation_languages.split(',') ?? [],
        [session?.translation_languages],
    );

  return (
    <Page>
        <Container>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                    <SoundToggle />
                </Grid>
                {!loading && <Grid item>
                    <FormControl variant="outlined">
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                            labelId="language-select-label"
                            variant="outlined"
                            label="Language"
                            value={language}
                            onChange={(event) => setLanguage(event.target.value as any)}
                        >
                            {availableLanguages.map((availableLanguage) => (
                                <MenuItem key={availableLanguage} value={availableLanguage}>
                                    {availableLanguage}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>}
                <Grid item>
                    <PlayPauseButton
                        playText="join"
                        pauseText="stop"
                        onStart={handleJoin}
                        onStop={onStopPlaying}
                    />
                </Grid>
            </Grid>
        </Container>
    </Page>
  );
}
