import React, {useEffect, useRef} from "react";
import styled from "styled-components";

interface ToggleProps {
    onText: string;
    offText: string;
    defaultEnabled?: boolean;
    onChange: (enabled: boolean) => void;
}

interface ContainerProps {
    enabled: boolean;
}

const Label = styled.div`
    position: relative;
    color: #666;
    transition: color 0.1s ease-in-out;
    padding: 0 16px;
    z-index: 2;
    -webkit-user-select: none;
    user-select: none;
`;

const LeftLabel = styled(Label)`
`;

const RightLabel = styled(Label)`
    margin-left: -8px;
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    bottom: 0;
    z-index: 1;
    border-radius: 1000px;
    transition: left 0.1s ease-in-out, width 0.1s ease-in-out;
    background-color: #fff;
    border: 1px solid #101010;
`;

const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    overflow: hidden;
    background-color: #ececf1;
    color: #101010;
    height: 48px;
    border-radius: 1000px;
    
    &:hover {
        background-color: #d8d8d8;
    }
    
    ${LeftLabel} {
        color: ${props => props.enabled ? '#101010' : '#666'};
    }
    
    ${RightLabel} {
        color: ${props => props.enabled ? '#666' : '#101010' };
    }
`;

export function Toggle({ onText, offText, defaultEnabled, onChange }: ToggleProps) {
    const [enabled, setEnabled] = React.useState<boolean>(defaultEnabled ?? false);
    const leftLabelRef = useRef<HTMLDivElement>(null);
    const rightLabelRef = useRef<HTMLDivElement>(null);
    const backgroundRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        updateBackground();
    }, [enabled]);

    const updateBackground = () => {
        if (leftLabelRef.current && rightLabelRef.current && backgroundRef.current) {
            if (enabled) {
                backgroundRef.current.style.left = '';
                backgroundRef.current.style.width = `${leftLabelRef.current.offsetWidth}px`;
            } else {
                backgroundRef.current.style.left = `${rightLabelRef.current.offsetLeft - 3}px`;
                backgroundRef.current.style.width = `${rightLabelRef.current.offsetWidth}px`;
            }
        }
    };

    function onClick() {
        const newEnabled = !enabled;
        setEnabled(newEnabled);
        onChange(newEnabled);
    }

    return (
        <Container enabled={enabled} onClick={onClick}>
            <LeftLabel ref={leftLabelRef}>{onText}</LeftLabel>
            <RightLabel ref={rightLabelRef}>{offText}</RightLabel>
            <Background ref={backgroundRef} />
        </Container>
    );
}
