import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {Header} from "./Header";

const StyledPage = styled.div`
    font-family: "Roboto Mono", monospace;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 8px;
`;

export function Page({ children }: PropsWithChildren) {
    return (
        <StyledPage>
            <Header />
            {children}
        </StyledPage>
    );
}
