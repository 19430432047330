import React, { useState } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useApiClient } from '../../base/hooks/useApiClient';

export function LoginPage() {
    const [formError, setFormError] = useState();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const apiClient = useApiClient();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const response = await apiClient.post('login', { username, password });

        if (!response.ok) {
            const { error } = await response.json();
            setFormError(error);

            return;
        }

        const { token } = await response.json();
        login(token);

        navigate('/');
    };

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    {formError && (
                        <Typography color="error" variant="body1" gutterBottom>
                            {formError}
                        </Typography>
                    )}
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Login
                    </Button>
                </form>
                <Typography variant="body2" mt={2}>
                    Don't have an account? <Link to="/register">Register here</Link>
                </Typography>
            </Box>
        </Container>
    );
}
