import React from 'react';
import styled from 'styled-components';
import {Button} from "../base";
import {useAuth} from "../../context";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 40px;
`;

const Title = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 12px;
`;

const NavButton = styled(Button)`
    background-color: #fff;
`;

const SubmitButton = styled.button`
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 0;
`;

export function Header() {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    function onLogout() {
        logout();
        navigate('/login');
    }

    return (
        <Container>
            <Title>SAI</Title>
                <NavButton>
                    {!isAuthenticated && <SubmitButton onClick={() => navigate('/login')}>Login</SubmitButton>}
                    {isAuthenticated && <SubmitButton onClick={onLogout}>Logout</SubmitButton>}
                </NavButton>
        </Container>
    );
}
