import {useEffect, useMemo} from "react";
import {WavRecorder, ChunkProcessor} from "../../../../libs/wavtools";

export function useWavRecorder() {
    const wavRecorder = useMemo(() => new WavRecorder({ sampleRate: 24000 }), []);

    const start = async (callback: ChunkProcessor) => {
        await wavRecorder.begin();
        await wavRecorder.clear();
        await wavRecorder.record(callback);
    };

    const stop = async () => {
        if (wavRecorder.getStatus() !== "ended") {
            await wavRecorder.end();
        }
    };

    useEffect(() => {
        return () => {
            stop();
        };
    }, []);

    return { start, stop };
}
