import React from 'react';
import {Page} from "../../layout";
import styled from "styled-components";
import {PlayPauseButton} from "../../base/PlayPauseButton";
import {useWavRecorder} from "./hooks/useWavRecorder";
import {useWebsocket} from "../../base/hooks";
import {useSoundEnabled} from "../../base/hooks";
import {useApiClient} from "../../base/hooks/useApiClient";

const Main = styled.div`
    flex-grow: 1;
    flex-shrink: 1 !important;
    display: flex;
    overflow: hidden;
    margin: 0 16px 24px;
    flex-direction: column;
`;

const Logs = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    border-top: 1px solid #e7e7e7;
    padding: 24px 0;
    flex-direction: row;
`;

const TranslationBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    flex-shrink: 0;
    overflow: hidden;
    height: 200px;
    min-height: 0;
    border-top: none;
`;

const TranslationBlockTitle = styled.div`
    flex-shrink: 0;
    padding-top: 16px;
    padding-bottom: 4px;
    position: relative;
`;

const TranslationBlockContent = styled.div`
    color: #6e6e7f;
    position: relative;
    flex-grow: 1;
    padding: 4px 0 8px;
    line-height: 1.2em;
    overflow: auto;
`;

const Actions = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

export function SessionPage() {
    const { soundEnabledRef, SoundToggle } = useSoundEnabled();
    const websocket = useWebsocket();
    const recorder = useWavRecorder();
    const apiClient = useApiClient();

    const onStartPlaying = async () => {
        await websocket.start((event) => {
            if (!soundEnabledRef.current) {
                console.log('Sound playback not enabled by user');
                return;
            }

            const data = JSON.parse(event.data);

            if (data.localPath) {
                const audio = new Audio(`${apiClient.getBaseUrl()}/${data.localPath}`);
                audio.play();
            }

            if (data.translation) {
                console.log(data.translation); // TODO: Update translation block
            }
        });

        await recorder.start((data) => {
            websocket.send(data.mono);
        });
    };

    const onStopPlaying = async () => {
        await recorder.stop();
        websocket.close();
    }

    return (
        <Page>
            <Main>
                <Logs>
                    <TranslationBlock>
                        <TranslationBlockTitle>translation</TranslationBlockTitle>
                        <TranslationBlockContent>awaiting connection...</TranslationBlockContent>
                    </TranslationBlock>
                </Logs>
                <Actions>
                    <SoundToggle />
                    <PlayPauseButton
                        playText="start"
                        pauseText="stop"
                        onStart={onStartPlaying}
                        onStop={onStopPlaying}
                    />
                </Actions>
            </Main>
        </Page>
    );
}
