import React, { useState } from 'react';
import {Page} from "../../layout";
import { Container, TextField, Button, Grid } from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useApiClient} from "../../base/hooks/useApiClient";

export function CreateSessionPage() {
    const [originalLanguage, setOriginalLanguage] = useState('');
    const [translationLanguages, setTranslationLanguages] = useState('');
    const navigate = useNavigate();
    const apiClient = useApiClient();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const response = await apiClient.post('sessions', {
            original_language: originalLanguage,
            translation_languages: translationLanguages.split(',').map((lang) => lang.trim())
        });

        if (!response.ok) {
            console.error('Error creating session');

            return;
        }

        const { sessionId } = await response.json();
        navigate(`/sessions/${sessionId}`);
    };

  return (
    <Page>
        <Container>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item>
                        <TextField
                            label="Original Language"
                            variant="outlined"
                            value={originalLanguage}
                            onChange={(e) => setOriginalLanguage(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Translation Languages"
                            variant="outlined"
                            value={translationLanguages}
                            onChange={(e) => setTranslationLanguages(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Create Session
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </Page>
  );
}
